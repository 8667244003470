export function setSearchParams(searchParams: URLSearchParams): void {
  const newUrl = new URL(location.href);

  newUrl.search = searchParams.toString();

  const newUrlString = newUrl.toString();

  window.history.pushState(
    { ...window.history.state, as: newUrlString, url: newUrlString },
    "",
    newUrlString
  );
}
