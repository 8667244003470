import Tooltip from "@mui/material/Tooltip";
import { useMemo } from "react";
import type { Account } from "../../api";
import { Link } from "../Link";
import classes from "./AccountLink.module.css";
import { AccountLinkTooltip } from "./AccountLinkTooltip";

interface AccountLinkProps {
  account: Account;
}

export function AccountLink(props: AccountLinkProps) {
  const { account } = props;

  const tooltipClasses = useMemo(() => ({ tooltip: classes.tooltip }), []);

  const linkAddress = useMemo(() => {
    const searchParams = new URLSearchParams();

    searchParams.set("f.id", `${account.id}`);

    if (typeof account.name !== "undefined") {
      searchParams.set("f.name", account.name);
    }

    return `/accounts?${searchParams.toString()}`;
  }, [account.id, account.name]);

  const displayName = useMemo(
    () => account.name ?? `<ID: ${account.id}>`,
    [account.id, account.name]
  );

  return (
    <Tooltip
      classes={tooltipClasses}
      title={<AccountLinkTooltip account={account} />}
    >
      <span>
        <Link href={linkAddress}>{displayName}</Link>
      </span>
    </Tooltip>
  );
}
