import { useContext, useLayoutEffect } from "react";
import type { Option } from "./Option";
import type { PickerState } from "./PickerState";
import { pickerStateContext } from "./pickerStateContext";

interface OptionsProps<T> {
  options: Option<T>[];
}

export function Options<T>(props: OptionsProps<T>) {
  const { options } = props;

  const state = useContext(pickerStateContext) as PickerState<T> | undefined;

  if (state === undefined) {
    throw new Error(
      "Unable to locate PickerState! Make sure to nest inside a Picker!"
    );
  }

  useLayoutEffect(() => {
    state.setOptions(options);

    return () => {
      state.setOptions([]);
    };
  }, [options, state]);

  return null;
}
