import Icon from "@mui/material/Icon";
import type { StaticImageData } from "next/image";
import type { DetailedHTMLProps, ImgHTMLAttributes } from "react";
import classes from "./BitmapIcon.module.css";

interface CreateBitmapIconOptions {
  alt: string;
  displayName: string;
  src: StaticImageData;
  srcWebp: StaticImageData;
}

export const createBitmapIcon = ({
  alt,
  displayName,
  src,
  srcWebp,
}: CreateBitmapIconOptions) => {
  const BitmapIcon = (
    props: DetailedHTMLProps<
      ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >
  ) => {
    return (
      <Icon className={classes.root}>
        <picture>
          <source srcSet={srcWebp.src} type="image/webp" />
          <img
            {...props}
            alt={alt}
            className={classes.image}
            height={src.height}
            src={src.src}
            width={src.width}
          />
        </picture>
      </Icon>
    );
  };

  BitmapIcon.displayName = displayName;

  return BitmapIcon;
};
