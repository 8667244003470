import { EMPTY, fromEvent, merge } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { getSearchParams } from "./getSearchParams";

let _searchParams$;

if (typeof window !== "undefined") {
  const urlChangeEmitter = new EventTarget();

  const pushState = window.history.pushState;

  window.history.pushState = function (...args) {
    pushState.apply(window.history, args);
    urlChangeEmitter.dispatchEvent(new CustomEvent("urlchanged"));
  };

  const replaceState = window.history.replaceState;

  window.history.replaceState = function (...args) {
    replaceState.apply(window.history, args);
    urlChangeEmitter.dispatchEvent(new CustomEvent("urlchanged"));
  };

  _searchParams$ = merge(
    fromEvent<PopStateEvent>(window, "popstate"),
    fromEvent<CustomEvent>(urlChangeEmitter, "urlchanged")
  ).pipe(
    startWith(undefined),
    map(() => getSearchParams())
  );
} else {
  _searchParams$ = EMPTY;
}

export const searchParams$ = _searchParams$;
